// import product_01_01 from '../fakedata/image/cartlane7.png'
// import product_01_02 from '../fakedata/image/cartlane2.png'
// import product_01_03 from '../fakedata/image/cartlane1.png'
// import product_01_04 from '../fakedata/image/cartlane2.png'

// import product_02_01 from '../fakedata/image/cartlane3.png'
// import product_02_02 from '../fakedata/image/cartlane3.png'
// import product_02_03 from '../fakedata/image/cartlane3.png'
// import product_02_04 from '../fakedata/image/cartlane4.jpg'

// import product_03_01 from '../fakedata/image/cartlane5.png'
// import product_03_02 from '../fakedata/image/cartlane2.png'
// import product_03_03 from '../fakedata/image/cartlane3.png'
// import product_03_04 from '../fakedata/image/cartlane4.jpg'

// import product_04_01 from '../fakedata/image/cartlane4.jpg'
// import product_04_02 from '../fakedata/image/cartlane2.png'
// import product_04_03 from '../fakedata/image/cartlane3.png'
// import product_04_04 from '../fakedata/image/cartlane4.jpg'

// import product_05_01 from '../fakedata/image/cartlane6.png'
// import product_05_02 from '../fakedata/image/cartlane2.png'
// import product_05_03 from '../fakedata/image/cartlane3.png'
// import product_05_04 from '../fakedata/image/cartlane4.jpg'

// import product_06_01 from '../fakedata/image/cartlane2.png'
// import product_06_02 from '../fakedata/image/cartlane2.png'
// import product_06_03 from '../fakedata/image/cartlane3.png'
// import product_06_04 from '../fakedata/image/cartlane4.jpg'

// import product_07_01 from '../fakedata/image/cartlane1.png'
// import product_07_02 from '../fakedata/image/cartlane2.png'
// import product_07_03 from '../fakedata/image/cartlane3.png'
// import product_07_04 from '../fakedata/image/cartlane4.jpg'

// import product_08_01 from '../fakedata/image/cartlane8.png'
// import product_08_02 from '../fakedata/image/cartlane2.png'
// import product_08_03 from '../fakedata/image/cartlane3.png'
// import product_08_04 from '../fakedata/image/cartlane4.jpg'

// const products = [
//     {
//         id: "01",
//         title: "Sleek Trendsetter Gold Necklace",
//         price: 23543,
//         delprice: 28546,
//         category: "Necklace",
//         image01: product_01_01,
//         image02: product_01_02,
//         image03: product_01_03,
//         image04: product_01_04,
//     },
//     {
//         id: "02",
//         title: "Elegant Gold Ring",
//         price: 23456,
//         delprice: 23456,
//         category: "Ring",
//         image01: product_02_01,
//         image02: product_02_02,
//         image03: product_02_03,
//         image04: product_02_04,
//     },
//     {
//         id: "03",
//         title: "Charming Ruby Bracelet",
//         price: 12585,
//         delprice: 12585,
//         category: "Bracelet",
//         image01: product_03_01,
//         image02: product_03_02,
//         image03: product_03_03,
//         image04: product_03_04,
//     },
//     {
//         id: "04",
//         title: "Vintage Silver Ring",
//         price: 54050,
//         delprice: 56046,
//         category: "Ring",
//         image01: product_04_01,
//         image02: product_04_02,
//         image03: product_04_03,
//         image04: product_04_04,
//     },
//     {
//         id: "05",
//         title: "Modern Pearl Bracelet",
//         price: 84651,
//         delprice: 84651,
//         category: "Bracelet",
//         image01: product_05_01,
//         image02: product_05_02,
//         image03: product_05_03,
//         image04: product_05_04,
//     },
//     {
//         id: "06",
//         title: "Sophisticated Diamond Earring",
//         price: 46466,
//         delprice: 46466,
//         category: "Earring",
//         image01: product_06_01,
//         image02: product_06_02,
//         image03: product_06_03,
//         image04: product_06_04,
//     },
//     {
//         id: "07",
//         title: "Stylish Leather Earring",
//         price: 32546,
//         delprice: 32546,
//         category: "Earring",
//         image01: product_07_01,
//         image02: product_07_02,
//         image03: product_07_03,
//         image04: product_07_04,
//     },
//     {
//         id: "08",
//         title: "Luxurious Gold Necklace",
//         price: 34535,
//         delprice: 34535,
//         category: "Necklace",
//         image01: product_08_01,
//         image02: product_08_02,
//         image03: product_08_03,
//         image04: product_08_04,
//     },
// ]

// export default products


import product_01_01 from '../fakedata/image/cartlane7.png';
import product_01_02 from '../fakedata/image/cartlane2.png';
import product_01_03 from '../fakedata/image/cartlane1.png';
import product_01_04 from '../fakedata/image/cartlane2.png';

import product_02_01 from '../fakedata/image/cartlane3.png';
import product_02_02 from '../fakedata/image/cartlane3.png';
import product_02_03 from '../fakedata/image/cartlane3.png';
import product_02_04 from '../fakedata/image/cartlane4.jpg';

import product_03_01 from '../fakedata/image/cartlane5.png';
import product_03_02 from '../fakedata/image/cartlane2.png';
import product_03_03 from '../fakedata/image/cartlane3.png';
import product_03_04 from '../fakedata/image/cartlane4.jpg';

import product_04_01 from '../fakedata/image/cartlane4.jpg';
import product_04_02 from '../fakedata/image/cartlane2.png';
import product_04_03 from '../fakedata/image/cartlane3.png';
import product_04_04 from '../fakedata/image/cartlane4.jpg';

import product_05_01 from '../fakedata/image/cartlane6.png';
import product_05_02 from '../fakedata/image/cartlane2.png';
import product_05_03 from '../fakedata/image/cartlane3.png';
import product_05_04 from '../fakedata/image/cartlane4.jpg';

import product_06_01 from '../fakedata/image/cartlane2.png'
import product_06_02 from '../fakedata/image/cartlane2.png'
import product_06_03 from '../fakedata/image/cartlane3.png'
import product_06_04 from '../fakedata/image/cartlane4.jpg'

import product_07_01 from '../fakedata/image/cartlane1.png'
import product_07_02 from '../fakedata/image/cartlane2.png'
import product_07_03 from '../fakedata/image/cartlane3.png'
import product_07_04 from '../fakedata/image/cartlane4.jpg'

import product_08_01 from '../fakedata/image/cartlane8.png'
import product_08_02 from '../fakedata/image/cartlane2.png'
import product_08_03 from '../fakedata/image/cartlane3.png'
import product_08_04 from '../fakedata/image/cartlane4.jpg'
const products = [
    {
        id: "01",
        title: "Sleek Trendsetter Gold Necklace",
        price: 23543,
        delprice: 28546,
        category: "Necklace",
        size: "7",
        material: "Gold",
        discount: 10,  // percentage discount
        image01: product_01_01,
        image02: product_01_02,
        image03: product_01_03,
        image04: product_01_04,
    },
    {
        id: "02",
        title: "Elegant Gold Ring",
        price: 23456,
        delprice: 25859,
        category: "Ring",
        size: "11",
        material: "Gold",
        discount: 5,
        image01: product_02_01,
        image02: product_02_02,
        image03: product_02_03,
        image04: product_02_04,
    },
    {
        id: "03",
        title: "Charming Ruby Bracelet",
        price: 12585,
        delprice: 15866,
        category: "Bracelet",
        size: "12",
        material: "Ruby",
        discount: 15,
        image01: product_03_01,
        image02: product_03_02,
        image03: product_03_03,
        image04: product_03_04,
    },
    {
        id: "04",
        title: "Vintage Silver Ring",
        price: 54050,
        delprice: 56046,
        category: "Ring",
        size: "15",
        material: "Silver",
        discount: 20,
        image01: product_04_01,
        image02: product_04_02,
        image03: product_04_03,
        image04: product_04_04,
    },
    {
        id: "05",
        title: "Modern Pearl Bracelet",
        price: 84651,
        delprice: 87522,
        category: "Bracelet",
        size: "12",
        material: "Pearl",
        discount: 10,
        image01: product_05_01,
        image02: product_05_02,
        image03: product_05_03,
        image04: product_05_04,
    },
    {
        id: "06",
        title: "Sophisticated Diamond Earring",
        price: 46466,
        delprice: 45200,
        category: "Earring",
        size: "13",
        material: "Pearl",
        discount: 10,
        image01: product_06_01,
        image02: product_06_02,
        image03: product_06_03,
        image04: product_06_04,
    },
    {
        id: "07",
        title: "Stylish Leather Earring",
        price: 32546,
        delprice: 35663,
        size: "5",
        material: "Pearl",
        discount: 10,
        category: "Earring",
        image01: product_07_01,
        image02: product_07_02,
        image03: product_07_03,
        image04: product_07_04,
    },
    {
        id: "08",
        title: "Luxurious Gold Necklace",
        price: 34535,
        delprice: 39522,
        category: "Necklace",
        size: "6",
        material: "Pearl",
        discount: 9,
        image01: product_08_01,
        image02: product_08_02,
        image03: product_08_03,
        image04: product_08_04,
    },
];

export default products;
